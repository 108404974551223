.past_experinces_container {
    background-color: rgb(233, 252, 213);
    /*background-color: var(--light-color);*/
    color: var(--nav--color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem; 
}

.experince_contianer { 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    margin: 1rem 0;
}

.experinces_card {
    width: 15rem;
    height: 15rem;
    background-color: var(--font-color);
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-bottom: 5px solid var(--nav--color);
    transition: all 0.1s;
}

.icon{
    height: 100px;
    background-color: var(--light-color);
    border-radius: 80%;
}

.experinces_card:hover {
    background-color: var(--font-color-hover);
    transform: scale(1.05);
}