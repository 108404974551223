.navigation_container {
    background-color: var(--nav-color);
    color: white;
    z-index: 2;
    top: 0;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--font-color) !important;

}

.navbar-light .navbar-brand {
    color: var(--font-color) !important;
}

.navbar-light .navbar-toggler-icon {
    filter: invert(1);
}

.links a:hover{
    background-color: var(--font-color-hover);
}