
.popup-box {
    position: fixed;
    left: 25%;
    top: 8%;
    background-color: white;
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
}

.box {
    position: relative;
    width: 40%;
    min-height: 300px;
    background-color: #ffff;
    /*
    border: 1px solid #999;
    border-radius: 4px;
    margin: 20px auto;
    margin-top: 100px;
    */
    
}

.my-role {
    position: relative;
    width: 40%;
    min-height: 300px;
    background-color: #ffff;
    /*
    border: 1px solid #999;
    border-radius: 4px;
    margin: 20px auto;
    margin-top: 100px;
    */
    
}

.header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: 'red';
    padding: 0.5rem;
}