.about_container {
    margin: 0rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    padding: 3rem;
    margin-top: 50%;
}

.about_container img{
    border-radius: 50%;
    border: 0.5px solid rgb(218, 218, 218);
    margin-bottom: 1rem;
    max-width: 140px;
    max-height: 140px;
    object-fit: scale-down;
}

.about_container p{
    margin-bottom: 3rem;
    
}

.about_text{
    max-width: 600px;
    padding-top: 10px;
}

/* ----------- Media Quieries ---------------*/

@media only screen and (max-width: 900px ){
    .about_container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px ){
    .about_container {
        max-width: 300;
    }
}