@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');
.APP {
  font-family: 'Nunito', sans-serif;
}

:root {
  --nav-color: hsl(210, 11%, 15%);
  --nav-color-hover: hsl(210, 11%, 45%);
  --nav-border-color: hsl(210, 11%, 45%);
  --light-color: hsl(209, 100%, 92%);
  --font-color: hsl(0%, 0%, 100%);
  --font-color: hsl(0, 0%, 100%);
  --font-color-hover: hsl(0, 0%, 50%);
}

.tsparticles-canvas {
  position: inherit !important;
  min-height: 10vh;
}

#tsparticles {
  position : absolute;
  width:100%;
  height:100%;
  z-index:-1;
}